#admin-panel{
  /* align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column; */
  background: #fff;
  min-height: 100vh;
}
#admin-header{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#admin-header li{
  text-align: center;
  display: inline-block;
  padding: 18px;
  color: #fff;
  font-weight: 800;
  cursor: pointer;
}
#admin-header a:last-child li{
  border-right: solid 0px #ccc; 
}

#admin-header .avatar{
  border-radius: 50px;
  margin-right: 15px;
}

.new-egg-uploads{
  display: flex;
  margin-top: 20px
}

.upload-wrap{
  width: 33.32%
}
.upload-wrap #drag-and-drop-filesimage{
  width: 100%;
  max-width: calc(100% - 20px) !important;
}
@media screen and (max-width: 600px) {

  .new-egg-uploads{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 20px
  }

}

.egg-card{
  background: rgba(0,0,0,0.05);
  height: 64.84px;
  overflow: hidden;
}
.egg-card.open{
  height: 100%;
}
.egg-card .panel{
  transition: height .2s ease-in-out;
}
.egg-card .image{
  max-width: 35px;
  margin-right: 15px;
}

.egg-card .image img{
  max-width: 35px;
  border-radius: 35px;
}

.egg-card video{
  max-width: 100%;
}

.egg-card .pannel{
}

.new-reposter-wrap{
  color: #333;
}
.reposter-add-feed .input-label{
  font-size: 12px !important;
}

.add-feed{
  background: rgb(20, 143, 135);
  border: none;
  color: #fff;
  margin-right: 15px;
  margin-left: auto;
} 

.create-reposter{
  background: rgb(0, 166, 255);
  border: none;
  color: #fff;
  margin-top: 40px;
  padding: 15px 25px;
  font-weight: 800;
  width: 100%;
  max-width: 400px;
}
#add-reposter{
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
#add-reposter.open{
  height: auto;
  opacity: 1;
}

.keywords-wrap span {
  padding: 10px;
  margin: 5px;
  background: rgba(0,0,0,0.1);
  display: inline-block;
}


.keywords-wrap span {
  padding: 10px;
  margin: 5px;
  background: rgba(0,0,0,0.1);
  display: inline-block;
}

.keyword-tag{
  cursor: pointer;
  background: rgba(9, 184, 0, 0.1) !important;
}

.mobile-toggle{
  cursor: pointer;
  color: #fff;
  position: fixed;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  padding: 7px 10px 6px;
  top: 22.5px;
  right: 55px;
}

.mobile-toggle.inside{
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 6px 10px 5px;
}

#nav-menu{
  display: none; 
}

#mobile-nav{
  display: block;
}

@media screen and (min-width: 768px){
  #nav-menu{
    display: block; 
  }
  #mobile-nav{
    display: none;
  }
}

#admin-panel .menu{
z-index: 15000;
}
.reposter{
  margin-bottom: 5px;
}
.new-reposter-button{
  
  width: 400px;
  margin: 30px auto 0;
}
@media screen and (max-width: 767px) {
  .new-reposter-button{
    margin-top: 15px;
    width: 100%;
  }
  label[for="request_approval"], label[for="activated"]{
    font-size: 11px !important;
  }
}
.mobile-toggle{
  top: 13.5px;
  right: 15px
}

.desktop, .desktop-flex{
  display: none !important;
}

@media screen and (min-width: 768px) {
  .mobile-toggle{
    display: none;
  }
  .mobile, .mobile-flex{
    display: none !important;
  }
  .desktop{
    display: block !important;
  }
  .desktop-flex{
    display: flex !important;
  }
}

.reposter.fields{
  &.active .feed{
    background: rgba(250, 255, 250, 1);
    &.inactive{ 
      background: rgba(255, 245, 245, 1);
    }
  }
  &.inactive .feed{
    background: rgba(255, 245, 245, 1);
  }
  background: #fff;
  padding-top: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  border: solid 1px rgba(0,0,0,.1);
  .preview{
    div{ width: 100% }

    .save-edits{
      margin-bottom: 15px;
    }
    
    .channel-status{
      display: flex;      
      cursor: pointer;
      justify-content: space-between;
      width: 80%
    }


    .channel-name, .channel-status, .feeds{
      padding-bottom: 10px;
      min-width: 120px;
    }

    @media screen and (max-width: 767px) {

      .channel-name{
        margin-top: 0px;
        margin-bottom: 0px;
        border: none;
        &:first-child{font-weight: 800;}
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      .channel-status{
        display: block;      
      }
      div{
        width: 33% !important
      }
      .channel-name{
        text-align: left;
        background: transparent;
        border: none;
      }
    }
    .title{
      font-size: 110%;
      font-weight: 800;
      margin-bottom: 10px;
    }
  }
  .reposter-details{
    background: rgba(12, 39, 46, 0.05);
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    .title{
      border-bottom: solid 1px #000;
      display: inline;
      p{margin-: 0;}
    }
  }
  .show-details{
    position: relative;
  }
  .channel-status span{
    margin-left: 15px;
  }

  .panel{
    .active{
      padding: 5px;
    }
    .actions{
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 767px) {
        justify-content: space-between;
        margin: 10px 0 15px;
      }
    }
  }
  .preview{
    border-bottom: solid 1px rgba(0,0,0,.1);
  }
  .reposter-details{
    height: 0px;
    background: #fff;
    overflow: hidden;
    &.open{
      height: unset
    }
    .feed {
      position: relative;
      padding-top: 10px;
      border-bottom: solid 1px rgba(0,0,0,.1);
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      .header{
        display: flex;
      }
      .panel{
        position: relative;
        height: 0px;
        overflow: hidden;
        &.open{
          height: unset
        }
      }

      @media screen and (min-width: 600px) {
        .actions{
          position: absolute;
          bottom: 15px;
          right: 5px;
        }
      }
    }
  }
  
}

#mobile-menu{
  .menu-items{
    padding: 40px 20px;
    a{
      text-decoration: none;
      color: #333;
      font-size: 120%;
      font-weight: 800;
    }
    li{
      list-style: none;
      padding: 10px 0;
      border-bottom: solid 2px #fff;
      transition: border-color .35s ease-in-out;
      &:hover{
        border-bottom-color: #333;
      }
    }
  }
}

.channel-id-helper{
  left: 15px;
  top: 17px;
  font-size: 10px;
  position: absolute
}

@import "../components/UI/css/index.scss"

