.list-manager{
  width: 100%;
  background: rgba(0,0,0,0.05);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;  
  border: solid 1px rgba(0,0,0,.1);
}
.list-manager-inner{
  width: 100%;
  .list-title{
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;  
    border: solid 1px rgba(0,0,0,.1);
    margin: 0;
    font-weight: 800;
    font-size: 22px;
  }
}
.list-manager-header{
  margin-top: 5px;
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px rgba(0,0,0,.1);
  padding: 5px 13px 8px;
  flex-direction: column;
  .filters-title{
    margin: 10px 0 0;
    font-weight: 800;
    font-size: 22px;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    .filters-title{
      margin: 0 15px 0 0;
      font-size: 18px;
    }
  }
  #items-per-page{
      width: 65px;
      min-width: 65px;
      margin-bottom: 0;
      margin-right: 15px;
  }
}

.list-manager-footer{
  background: rgba(255,255,255,1);
  margin-top: 5px;
  border: solid 1px rgba(0,0,0,.1);
  .pagination{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination-tab{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    color: rgba(0,0,0,0.2);
    border: solid 1px rgba(0,0,0,0.2);
    margin: 5px;
    &.active{
      color: #333;
      border-color: #333;
    }
  }
}

.list-manager-results{
  display: flex;
  flex-direction: column;
}