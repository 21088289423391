
.control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  padding: 30px;
  width: 200px;
  height: 210px;
  margin: 10px;
}
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 0px;
  left: 0;
  height: 16px;
  width: 16px;
  background: #fff;
  border: 1px solid #ccc;
/*
  .control:hover input:not([disabled]):checked ~ &,
  .control input:checked:focus ~ &
    border-color: #666
    */
}
.control--checkbox .control__indicator {
  border-radius: 3px;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input:not([disabled]) ~ .control__indicator,
.control input:focus ~ .control__indicator {
  border-color: #666;
}
.control input:checked ~ .control__indicator {
  background: #fff;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 12px;
  border: solid #34bb92;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 5px;
  top: 5px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #34bb92;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b17;
}
.select {
  position: relative;
  display: inline-block;
  width: 100%;
}
.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select select::-ms-expand {
  display: none;
}
.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}
.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: #000;
}
.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}







// <div class="container">
//   <div class="control-group">
//     <h1>Checkboxes</h1>
//     <label class="control control--checkbox">First checkbox
//       <input type="checkbox" checked="checked"/>
//       <div class="control__indicator"></div>
//     </label>
//     <label class="control control--checkbox">Second checkbox
//       <input type="checkbox"/>
//       <div class="control__indicator"></div>
//     </label>
//     <label class="control control--checkbox">Disabled
//       <input type="checkbox" disabled="disabled"/>
//       <div class="control__indicator"></div>
//     </label>
//     <label class="control control--checkbox">Disabled & checked
//       <input type="checkbox" disabled="disabled" checked="checked"/>
//       <div class="control__indicator"></div>
//     </label>
//   </div>
//   <div class="control-group">
//     <h1>Radio buttons</h1>
//     <label class="control control--radio">First radio
//       <input type="radio" name="radio" checked="checked"/>
//       <div class="control__indicator"></div>
//     </label>
//     <label class="control control--radio">Second radio
//       <input type="radio" name="radio"/>
//       <div class="control__indicator"></div>
//     </label>
//     <label class="control control--radio">Disabled
//       <input type="radio" name="radio2" disabled="disabled"/>
//       <div class="control__indicator"></div>
//     </label>
//     <label class="control control--radio">Disabled & checked
//       <input type="radio" name="radio2" disabled="disabled" checked="checked"/>
//       <div class="control__indicator"></div>
//     </label>
//   </div>
//   <div class="control-group">
//     <h1>Select boxes</h1>
//     <div class="select">
//       <select>
//         <option>First select</option>
//         <option>Option</option>
//         <option>Option</option>
//       </select>
//       <div class="select__arrow"></div>
//     </div>
//     <div class="select">
//       <select>
//         <option>Second select</option>
//         <option>Option</option>
//         <option>Option</option>
//       </select>
//       <div class="select__arrow"></div>
//     </div>
//     <div class="select">
//       <select disabled="disabled">
//         <option>Disabled</option>
//         <option>Option</option>
//         <option>Option</option>
//       </select>
//       <div class="select__arrow"></div>
//     </div>
//   </div>
// </div>